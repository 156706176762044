import * as React from "react"
import { graphql } from "gatsby"
import "../contactPage.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import girl4 from "../images/girls/4.png"
import girl26 from "../images/girls/26.png"
import girl23 from "../images/girls/23.png"
import girl5 from "../images/girls/5.png"

import appstore from "../images/appstore.png"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お問い合わせ" />
      <section className="PageTitle" id="pagetop">
        <div class="wrap">
          <img src={ girl4 } className="girls_img"/>
          <img src={ girl26 } className="girls_img"/>
          <div>
            <h2>[　Contact　]</h2>
            <h1>お問い合わせページ</h1>
          </div>
          <img src={ girl23 } className="girls_img"/>
          <img src={ girl5 } className="girls_img"/>
        </div>
        <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
      </section>
      <section className="ContactPage">
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLScGGafiaAQxPBY11rtL6b5Me7uQyJwiw6XIWYL6lsPv86i1hg/viewform?embedded=true" 
          width="100%"
          height="1300"
          frameborder="0" 
          marginheight="0" 
          marginwidth="0">
            読み込んでいます…
        </iframe>
      </section>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
